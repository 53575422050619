import './bootstrap';
import AgentDebugLog from './components/AgentDebugLog';
import LogTabs from './components/LogTabs';
import CreateQuote from './components/CreateQuote';
import DashboardUserSearch from './components/dashboard/SearchUser';
import UserTierChange from './components/dashboardSearch/UserTierChange';
import UsersIndexPage from './components/dashboard/UsersIndexPage';
import FeatureStatisticsMetrics from './components/dashboard/FeatureStatisticsMetrics';

document.addEventListener('DOMContentLoaded', function () {
    function setTab(tab) {
        document.querySelectorAll('.tab-content').forEach(function (content) {
            content.classList.add('hidden');
            if (content.querySelector('iframe')) {
                content.querySelector('iframe').style.display = 'none';
            }
        });

        var contentDiv = document.getElementById(`content-${tab}`);
        var iframe = document.getElementById(`iframe-${tab}`);
        if (!contentDiv || !iframe) {
            return
        }
        contentDiv.classList.remove('hidden');
        iframe.style.display = 'block';
        if (!iframe.src) {
            iframe.src = iframe.getAttribute('data-src');
        }

        document.querySelectorAll('.tab-link').forEach(function (link) {
            link.classList.remove('border-l', 'border-t', 'border-r', 'rounded-t', 'text-blue-700');
        });

        document.querySelector(`[data-tab="${tab}"]`).classList.add('border-l', 'border-t', 'border-r', 'rounded-t', 'text-blue-700');
    }

    document.querySelectorAll('.tab-link').forEach(function (link) {
        console.log(link);
        link.addEventListener('click', function (event) {
            event.preventDefault();
            setTab(link.getAttribute('data-tab'));
        });
    });

    if (document.getElementById(`content-accounts`)) {
        setTab('accounts');
    }
});

document.addEventListener('DOMContentLoaded', function () {
    var trigger = document.getElementById('dropdown-trigger');
    var menu = document.getElementById('dropdown-menu');

    function toggle() {
        menu.style.display = (menu.style.display === 'none' || menu.style.display === '') ? 'block' : 'none';
    }

    function close() {
        menu.style.display = 'none';
    }

    trigger.addEventListener('click', toggle);
    document.addEventListener('click', function (event) {
        if (!document.getElementById('account-dropdown').contains(event.target)) {
            close();
        }
    });
});

document.addEventListener('DOMContentLoaded', function () {
    var open = false;
    var responsiveMenu = document.getElementById('responsive-menu');
    var menuIcon = document.getElementById('menu-icon');
    var closeIcon = document.getElementById('close-icon');

    function toggle() {
        open = !open;
        responsiveMenu.style.display = open ? 'block' : 'none';
        menuIcon.classList.toggle('hidden', open);
        closeIcon.classList.toggle('hidden', !open);
    }

    document.addEventListener('click', function (event) {
        var hamburger = document.querySelector('#hamburger');
        if (hamburger && hamburger.contains(event.target)) {
            toggle();
        } else if (!document.querySelector('nav').contains(event.target)) {
            open = false;
            responsiveMenu.style.display = 'none';
            menuIcon.classList.remove('hidden');
            closeIcon.classList.add('hidden');
        }
    });
});

window.useStripeShippingAddress = function () {
    let shippingAddress = {
        line1: document.querySelector('input[name="stripe_shipping_address-line1"]').value,
        line2: document.querySelector('input[name="stripe_shipping_address-line2"]').value,
        city: document.querySelector('input[name="stripe_shipping_address-city"]').value,
        state: document.querySelector('input[name="stripe_shipping_address-state"]').value,
        postalCode: document.querySelector('input[name="stripe_shipping_address-postal_code"]').value,
    };

    let billingAddress = {
        line1: document.querySelector('input[name="stripe_address[line1]"]'),
        line2: document.querySelector('input[name="stripe_address[line2]"]'),
        city: document.querySelector('input[name="stripe_address[city]"]'),
        state: document.querySelector('input[name="stripe_address[state]"]'),
        postalCode: document.querySelector('input[name="stripe_address[postal_code]"]'),
    };

    billingAddress.line1.value = shippingAddress.line1;
    billingAddress.line2.value = shippingAddress.line2;
    billingAddress.city.value = shippingAddress.city;
    billingAddress.state.value = shippingAddress.state;
    billingAddress.postalCode.value = shippingAddress.postalCode;
}

new AgentDebugLog;

if (document.getElementById('create-reftab-quote-form')) {
    new CreateQuote;
}

if (document.getElementById('reftab-user-logs-container')) {
    new LogTabs('reftab-user-logs-container');
}

// DASHBOARD
new DashboardUserSearch;

// DASHBOARD SEARCH - Main page where user info is located
new UserTierChange;

new UsersIndexPage;

new FeatureStatisticsMetrics;

document.addEventListener('DOMContentLoaded', function () {
    if (window.tinymce) {
        tinymce.init({
            selector: '#template',
            license_key: 'acwff1gmctgll7bwjmnw2io3shywqeocf7q7wci167tj5cli',
            plugins: 'code',
            toolbar: 'code'
        });
    }
});
